import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { isAllDashboardDataLoaded, loadUnavailableDashboardData } from "../../state/reducers/dashboardReducerHelpers";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { TBackendHistoric, TBackendKpi, TBackendMapData, TBackendNetworkNames, TBackendTicker } from "../../types/backend-data";
import { KpiSection } from "../../sections/KpiSection";
import { MapSection } from "../../sections/MapSection";
import { AllocationSection } from "../../sections/AllocationSection";
import { HistoricSection } from "../../sections/HistoricSection";
import { setSelectedNetwork } from "../../state/actions/dashboardActions";
import { NetworkSelector } from "../../components/NetworkSelector";
import { ComparisonSection } from "../../sections/ComparisonSection";
import { BackendOfflineAlert } from "../../components/BackendOfflineAlert";
import { PageTransitionWrapper } from "../../components/PageTransitionWrapper";
import { isMapDataAvailable, showAllocationSection } from "./mainDashboardHelpers";
import { Transition } from "@headlessui/react";
import { styleConstants } from "../../style-constants";
import { HeroSection } from "../../sections/HeroSection";
import { PoweredByCcri } from "../../components/PoweredByCcri";


interface IMainDashboardProps {

}


export const MainDashboard: React.FC<IMainDashboardProps> = (props) => {

  const dispatch = useAppDispatch()

  const [dashboardLoading, setDashboardLoading] = useState<boolean>(true);
  const [isUserOnline, setIsUserOnline] = useState<boolean>(navigator.onLine);


  const tickers = useAppSelector<TBackendTicker>(state => state.dashboard.tickers);
  const kpiData = useAppSelector<TBackendKpi>(state => state.dashboard.kpiData);
  const mapData = useAppSelector<TBackendMapData>(state => state.dashboard.mapData);
  const historicData = useAppSelector<TBackendHistoric>(state => state.dashboard.historicData);
  const selectedNetworkName = useAppSelector<TBackendNetworkNames>(state => state.dashboard.selectedNetwork)
  const isBackendOffline = useAppSelector<boolean>(state => state.session.isBackendOffline)


  useEffect(() => {

    //load data if not yet loaded, on initial page load
    if (false === isAllDashboardDataLoaded()) {
      setDashboardLoading(true)
      loadUnavailableDashboardData(dispatch)
    }

  }, [dispatch]);


  useEffect(() => {

    //check if all data there on each update, set loading to false if all are there
    if (true === isAllDashboardDataLoaded()) {
      if (undefined === selectedNetworkName) {
        // on initial load, set selectedNetworkName to the first network
        const networkNames = Object.keys(tickers) as TBackendNetworkNames[]
        dispatch(setSelectedNetwork(networkNames[0]))
      }
      setDashboardLoading(false)
    } else if (true === isBackendOffline) {
      setDashboardLoading(false)
    }
  }, [dispatch, tickers, kpiData, mapData, historicData, isBackendOffline, selectedNetworkName]);


  useEffect(() => {
    const onlineHandler = () => {
      setIsUserOnline(true);
    }

    const offlineHandler = () => {
      setIsUserOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);


    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);


  const renderComponent = () => {
    if (dashboardLoading) {
      return <LoadingSpinner />
    } else if (false === dashboardLoading && true === isBackendOffline) {
      return <BackendOfflineAlert />
    } else {

      return (
        <div
          className="flex flex-col w-full gap-y-8 mb-8">
          <HeroSection isUserOnline={isUserOnline} />
          <NetworkSelector />
          <Transition
            key={`main-dashboard-${selectedNetworkName}`}
            appear={true}
            show={true}
            enter={`${styleConstants.transitionStandard}`}
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave={`${styleConstants.transitionStandard}`}
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="flex flex-col gap-y-12 items-center justify-center w-full md:px-16 mt-4">
              {
                isMapDataAvailable(selectedNetworkName, mapData) &&
                <MapSection
                  networkMapDataEntry={mapData[selectedNetworkName!]}
                />}
              <KpiSection
                ticker={tickers[selectedNetworkName!]}
                kpiData={kpiData[selectedNetworkName!]}
              />
              {
                showAllocationSection(selectedNetworkName) &&
                <AllocationSection
                  networkName={selectedNetworkName}
                  isUserOnline={isUserOnline}
                />}
              <HistoricSection
                historicDataNetwork={historicData[selectedNetworkName!]}
              />
              <ComparisonSection
                kpiData={kpiData[selectedNetworkName!]}
                networkName={selectedNetworkName}
              />
              <PoweredByCcri
                positioningClasses='mt-8'
                isLargeVariant
              />
            </div>
          </Transition>
        </div>
      )
    }
  }


  return (
    <PageTransitionWrapper
      component={renderComponent()}
    />
  )
}