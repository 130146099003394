import React, { useState } from 'react';
import { Switch } from '@headlessui/react'
import { styleConstants } from '../../style-constants';


interface ISwitchProps {
  leftLabel: string,
  rightLabel: string,
  colorChange: boolean,
  defaultEnabled: boolean,
  noColorChange?: boolean,
  customLabelWidthClass?: string,
  switchInactive?: boolean,
  onChange: (newState: boolean) => void,
}

export const CustomSwitch: React.FC<ISwitchProps> = (props) => {

  const [enabled, setEnabled] = useState(props.defaultEnabled)

  const onSwitchChange = () => {
    if (props.switchInactive) {
      return
    } else {
      const newState = !enabled
      setEnabled(newState)
      props.onChange(newState)
    }
  }

  const commonLabelClasses = `${props.customLabelWidthClass ? props.customLabelWidthClass : "w-[80px]"} cursor-pointer`
  const backgroundColor = props.noColorChange ? 'bg-hoverPurple' : (enabled ? 'bg-hoverPurple' : 'bg-gray-900')
  const inactiveSwitchClasses = "!cursor-default !pointer-events-none !opacity-40"
  
  return (
    <Switch.Group>
      <div className={`flex flex-col xxs:flex-row gap-y-6 sm:gap-y-0 items-center my-2 ${props.switchInactive ? inactiveSwitchClasses : ""}`}>
        <Switch.Label
          className={`mr-5 text-right ${commonLabelClasses} ${enabled ? "font-light" : "font-semibold"} ${styleConstants.transitionStandard}`}
          passive={props.switchInactive}
        >{props.leftLabel}
        </Switch.Label>
        <Switch
          checked={enabled}
          onChange={onSwitchChange}
          className={`${backgroundColor} transform rotate-90 xxs:rotate-0 relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none ring-2 ring-white`}
        >
          <span
            className={`${enabled ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
        <Switch.Label
          className={`ml-5 text-left ${commonLabelClasses} ${enabled ? "font-semibold" : "font-light"} ${styleConstants.transitionStandard}`}
          passive={props.switchInactive}
        >
          {props.rightLabel}
        </Switch.Label>
      </div>
    </Switch.Group>
  )
};

