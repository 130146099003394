
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setSelectedNetwork } from '../../state/actions/dashboardActions';
import { CustomSwitch } from '../CustomSwitch';
import { TBackendNetworkNames } from '../../types/backend-data';


interface INetworkSelectorProps {
    isInactive?: boolean
}

export const NetworkSelector: React.FC<INetworkSelectorProps> = (props) => {


    const dispatch = useAppDispatch()

    const selectedNetworkName = useAppSelector<TBackendNetworkNames>(state => state.dashboard.selectedNetwork)


    const handleChangeSelectedNetworkSwitch = (newState: boolean) => {
        if (newState === true) {
            dispatch(setSelectedNetwork('zkevm'))
        } else {
            dispatch(setSelectedNetwork('pos'))
        }
    }


    const isDefaultEnabled = () => {
        if (selectedNetworkName === 'zkevm') {
            return true
        } else {
            return false
        }
    }


    const switchMenu = (
        <CustomSwitch
            colorChange={true}
            defaultEnabled={isDefaultEnabled()}
            leftLabel="Polygon PoS"
            rightLabel="Polygon zkEVM"
            onChange={(newState) => handleChangeSelectedNetworkSwitch(newState)}
            noColorChange
            switchInactive={props.isInactive}
            customLabelWidthClass='w-[120px]'
        />
    )


    return (
        <div className="flex justify-center">
            {switchMenu}
        </div>
    );

    /* 

    to be used if expanded to more then two networks
    
    const tickers = useAppSelector<TBackendTicker>(state => state.dashboard.tickers);
    const handleChangeSelectedNetworkDropdown = (newNetwork: TBackendNetworkNames) => {
        dispatch(setSelectedNetwork(newNetwork))
    }
    const selectedNetworkName = useAppSelector<TBackendNetworkNames>(state => state.dashboard.selectedNetwork)
    const dropdownMenu = (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="inline-flex w-full justify-center rounded-md bg-white bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                    <span className='w-[150px]'>

                        {formatNetworkName(selectedNetworkName)}
                    </span>
                    <ChevronDownIcon
                        className="ml-2 -mr-1 h-5 w-5 text-violet-200"
                        aria-hidden="true"
                    />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter={`${styleConstants.transitionStandard}`}
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave={`${styleConstants.transitionStandard}`}
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                        {Object.entries(tickers).map((ticker) => {
                            const networkName: TBackendNetworkNames = ticker[0] as TBackendNetworkNames
                            return (
                                <Menu.Item
                                    key={`network-selector-${networkName}`}>
                                    {({ active }) => (
                                        <button
                                            className={`${networkName === selectedNetworkName ? 'bg-hoverPurple text-white' : `${active ? "bg-paragraphGray text-white" : "bg-white text-black"}`
                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            onClick={() => handleChangeSelectedNetworkDropdown(networkName)}
                                        >
                                            {formatNetworkName(networkName)}
                                        </button>
                                    )}
                                </Menu.Item>
                            )
                        })}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
    */

};
