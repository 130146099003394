import React, { useState } from 'react';
import { TBackendAddressAllocationRequest, TBackendAddressAllocationResult, TBackendNetworkNames } from '../../types/backend-data';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { AlertBox } from '../../components/AlertBox';
import { Button } from '../../components/Button';
import { backendGetAddressAllocationData } from '../../services/backend-service';
import { customErrors } from '../../services/backend-erros';
import { formatEmissionsDynamic } from '../../pages/MainDashboard/mainDashboardHelpers';
import { Paragraph } from '../../components/Paragraph';
import { styleConstants } from '../../style-constants';
import { Heading } from '../../components/Heading';
import { Transition } from '@headlessui/react';
import { InfoTooltip } from '../../components/InfoTooltip';


interface IAllocationSectionProps {
    networkName: TBackendNetworkNames,
    isUserOnline: boolean

}

export const AllocationSection: React.FC<IAllocationSectionProps> = (props) => {

    const [addressInputValue, setAddressInputValue] = useState<string>('');
    const [calculationResult, setCalculationResult] = useState<TBackendAddressAllocationResult | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isResultLoading, setIsResultLoading] = useState<boolean>(false)



    const isValidAddress = (address: string) => {
        if (typeof address !== 'string') {
            return false;
        }
        // Check length
        if (address.length !== 42) {
            return false;
        }
        // Check prefix
        if (!address.startsWith('0x')) {
            return false;
        }
        // Check if remaining characters are valid hex
        const hexChars = '0123456789abcdefABCDEF';
        for (let i = 2; i < address.length; i++) {
            if (hexChars.indexOf(address[i]) === -1) {
                return false;
            }
        }
        return true;
    }

    const requestAllocationData = async () => {
        setIsResultLoading(true)
        try {
            const allocationRequestData: TBackendAddressAllocationRequest = {
                address: addressInputValue,
                network: props.networkName
            }
            const allocationData = await backendGetAddressAllocationData(allocationRequestData);
            if (false === allocationData.allocation.allocation_successful) {
                throw new Error("ccri api error")
            }
            setCalculationResult(allocationData)
        }
        catch (error: any) {
            if (error instanceof customErrors.noTxsFound) {
                setErrorMessage("No transactions found for provided address. Please check address!")
            } else {
                setErrorMessage("An error ocurred. Please try again later!")
            }
        } finally {
            setIsResultLoading(false)
        }
    }

    const handleSubmit = () => {
        if (!isValidAddress(addressInputValue)) {
            setErrorMessage("Provided address not valid.");
            return;
        } else {
            requestAllocationData()
        }
    }

    const repeatCalculation = () => {
        setErrorMessage(undefined)
        setCalculationResult(undefined)
        setAddressInputValue("")
    }

    const handleInputFormTypeIn = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
        setAddressInputValue(changeEvent.target.value)
    }

    const renderInputComponent = () => {

        const renderFormInputTextBox = (boxDefaultText: string) => {
            return (
                <input
                    className='w-full bg-black border border-white rounded-lg px-2 py-1 font-thin'
                    type="text"
                    value={addressInputValue}
                    onChange={e => handleInputFormTypeIn(e)}
                    placeholder={boxDefaultText}
                />
            )
        }

        const content = (
            <div
                className='w-full'
            >
                <div
                    className='w-full lg:w-8/12 flex flex-col items-center lg:items-baseline justify-center space-y-8 lg:space-y-4'
                >
                    <div className='flex flex-col space-y-2'>
                        <Heading text={"Calculate your Emissions"} isSubheading={true} />
                        <Paragraph
                            content={"Insert your address and get an overview of the emissions that you are responsible for."}
                            isWhiteText
                            isThinText
                            customStyle='lg:w-3/4 text-center sm:text-left'
                        />
                    </div>
                    <form
                        className='flex flex-col lg:flex-row items-center justify-center lg:justify-start w-full space-x-0 lg:space-x-4 space-y-4 lg:space-y-0'
                        onSubmit={handleSubmit}
                    >
                        <div
                            className='w-3/4 md:w-2/3 shrink '
                        >
                            <div className={styleConstants.mobileClasses}>
                                {renderFormInputTextBox("Your address...")}
                            </div>
                            <div className={styleConstants.desktopClasses}>
                                {renderFormInputTextBox("Insert your address here...")}
                            </div>
                        </div>
                        <div>
                            <Button
                                onButtonClick={handleSubmit}
                                buttonText='Submit'
                                isSubmitButton
                            />
                        </div>
                    </form>
                </div>
            </div>

        )

        return content
    }

    const renderResultComponent = () => {

        const allocationResult = calculationResult!
        const allocation = allocationResult.allocation


        const renderAllocationWarning = () => {
            if (allocationResult.total_earliest_limit || allocationResult.allocation.needs_prediction) {
                let warningText = ""
                if (allocationResult.total_earliest_limit) {
                    warningText += "The number of transactions analyzed was limited.\n"
                    if (allocationResult.transactions_limited) {
                        warningText += "Only the last 10,000 normal transactions were included.\n"
                    }
                    if (allocationResult.internal_transactions_limited) {
                        warningText += "Only the last 10,000 internal transactions were included.\n"
                    }
                }
                if (allocationResult.allocation.needs_prediction) {
                    warningText += "There is only activity for today in the analyzed period.\nPlease note that emission data for today are not final yet."
                }
                return (<div className='flex flex-row space-x-2 items-center justify-center'>
                    <Paragraph
                        content={`There are warnings for your calculation:`}
                        isWhiteText
                        isThinText
                        isCaption
                        customStyle='text-center'
                    />
                    <InfoTooltip
                        text={warningText}
                        type='warning'
                        customTooltipStyle='!max-w-none'
                        customIconHeight='h-[17px]'
                    />
                </div>)
            } else {
                return <></>
            }


        }

        const content = (
            <div
                className='flex flex-col items-center space-y-4 w-full'
            >
                <div className='flex flex-col items-center'>
                    <Heading text={`Total emissions: ${formatEmissionsDynamic(allocation.emissions_sum, 3)}`} isSubheading={true} />
                    <Paragraph
                        content={addressInputValue}
                        isCaption
                    />
                </div>

                <div className='w-full flex justify-center'>
                    <div className='flex flex-col items-center w-[350px]'>
                        <div className='flex w-full justify-between'>
                            <div>
                                <Paragraph
                                    content={`Emissions of holdings:`}
                                    isWhiteText
                                    isThinText
                                />
                                <Paragraph
                                    content={`Emissions of transactions:`}
                                    isWhiteText
                                    isThinText
                                />

                            </div>
                            <div className='text-right'>
                                <Paragraph
                                    content={`${formatEmissionsDynamic(allocation.emissions_holdings, 3)}`}
                                    isWhiteText
                                    isThinText
                                />
                                <Paragraph
                                    content={`${formatEmissionsDynamic(allocation.emissions_tx, 3)}`}
                                    isWhiteText
                                    isThinText
                                />
                            </div>

                        </div>
                    </div>
                </div>

                <div className='flex flex-col space-y-1 justify-center'>
                    <Paragraph
                        content={`Calculated emissions refer to activity ${allocation.allocation_start_day === allocation.allocation_end_day ? `on ${allocation.allocation_start_day}` : `from ${allocation.allocation_start_day} to ${allocation.allocation_end_day}`}. Only for information purposes.`}
                        isWhiteText
                        isThinText
                        isCaption
                        customStyle='text-center'
                    />
                    {renderAllocationWarning()}
                </div>
                <div>
                    <Button onButtonClick={repeatCalculation} buttonText='Change address' />
                </div>
            </div>
        )

        return content
    }

    const renderAlertComponent = () => {
        return (
            <div className='flex justify-center'>
                <AlertBox
                    text={errorMessage!}
                    type='error'
                    buttonText="Try again"
                    onButtonClick={repeatCalculation} />
            </div>
        )
    }

    const renderLoadingComponent = () => {
        return (
            <LoadingSpinner />
        )
    }


    const renderBackgroundVideoIframe = () => {
        return (props.isUserOnline ?
            <div className={`absolute hidden md:block w-full h-full overflow-hidden ${styleConstants.boxStyle}`}>
                <iframe
                    title='allocation-section-video'
                    className="w-[245%] h-[200%] lg:w-[140%] lg:h-[200%] xl:w-[104%] xl:h-[200%] absolute top-1/2 transform -translate-y-1/2 right-0 flex flex-col items-end"
                    src="https://player.vimeo.com/video/791149043?h=8c483c169e&autoplay=1&loop=1&title=0&byline=0&portrait=0&background=1"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen>
                </iframe>
            </div>
            :
            <></>
        )
    }


    const renderComponent = () => {

        let currentComponent = ""
        let content = <></>
        let background = <></>

        if (isResultLoading) {
            content = renderLoadingComponent()
            currentComponent = "loading"
        } else {
            if (undefined === calculationResult && undefined === errorMessage) {
                content = renderInputComponent()
                background = renderBackgroundVideoIframe()
                currentComponent = "input"
            } else if (calculationResult && undefined === errorMessage) {
                content = renderResultComponent()
                currentComponent = "result"
            } else {
                content = renderAlertComponent()
                currentComponent = "alert"
            }
        }

        return (
            <div
                className={`relative w-full flex items-stretch min-h-[260px]`}
            >
                {background}
                <div
                    className={`w-full flex flex-col space-y-8 items-center justify-center ${styleConstants.boxStyle}`}
                >
                    <Transition
                        className={"w-full"}
                        key={currentComponent}
                        appear={true}
                        show={true}
                        enter={`${styleConstants.transitionStandard}`}
                        enterFrom="opacity-0 scale-50"
                        enterTo="opacity-100 scale-100"
                        leave={`${styleConstants.transitionStandard}`}
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-50"
                    >
                        {content}

                    </Transition>
                </div>
            </div>
        )
    }

    return (
        <div
            className='w-full'
        >
            {renderComponent()}
        </div>
    )
};


