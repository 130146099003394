import { Heading } from "../../components/Heading"
import { Paragraph } from "../../components/Paragraph"
import { styleConstants } from "../../style-constants"


interface IHeroSectionProps {
    isUserOnline: boolean
}


export const HeroSection: React.FC<IHeroSectionProps> = (props) => {



    const renderVideoIframe = () => {
        return (props.isUserOnline ?
            <div
                className="relative flex justify-center md:justify-end w-full h-full"
            >
                <iframe
                    title="hero-logo"
                    className="w-[350px] h-[200px] md:absolute md:top-1/2 md:transform md:-translate-y-1/2 md:translate-x-32 -scale-x-100"
                    src="https://player.vimeo.com/video/791149033?h=6ee4a09096&autoplay=1&loop=1&title=0&byline=0&portrait=0&background=1"
                    allow="autoplay; fullscreen; picture-in-picture"
                >
                </iframe>
            </div>

            :
            <></>
        )
    }


    const headingText = "Explore The Polygon Network's Sustainability"
    const subheadingText = "At Polygon Labs, we believe blockchain technology has the potential to support and scale lasting positive impact on the world."


    return (
        <div
            className="flex flex-col flex-grow items-stretch gap-8">
            <div className="flex flex-col md:flex-row w-full h-full gap-y-8 md:gap-y-0">
                <div className="flex flex-col gap-y-8 sm:gap-y-4 z-10 w-full md:min-w-[360px] md:w-3/5 lg:w-4/5">
                    <Heading
                        text={headingText}
                    />
                    <div>
                        <div className="w-full lg:w-2/3">
                            <div className={styleConstants.mobileClasses}>
                                <Paragraph
                                    content={subheadingText}
                                    isCenter
                                />
                            </div>
                            <div className={styleConstants.desktopClasses}>
                                <Paragraph
                                    content={subheadingText}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-grow md:justify-end w-full md:w-1/3">
                    {renderVideoIframe()}
                </div>
            </div>
        </div>
    )
}