
interface IHeadingProps {
    text: string,
    isSubheading?: boolean,
    hasBottomPadding?: boolean,
    hasSmallBottomPadding?: boolean,
    hasLargeBottomPadding?: boolean,
    hasMediumSmallBottomPadding?: boolean,
    isSmTextCenter?: boolean
    customStyle?: string,
}


export const Heading: React.FC<IHeadingProps> = (props) => {

    return (
        <h1
            className={`text-center
        	${props.isSubheading ? "text-[1.4rem] font-[500]" : `text-3xl sm:text-[2.50rem] font-[500] leading-normal`} 
            ${props.hasBottomPadding ? "pb-16" : ""} 
            ${props.hasSmallBottomPadding ? "pb-4" : ""} 
            ${props.hasMediumSmallBottomPadding ? "pb-8" : ""} 
            ${props.hasLargeBottomPadding ? "pb-24" : ""} 
            ${props.customStyle ? props.customStyle : ""}
            ${props.isSmTextCenter ? "sm:text-center" : "sm:text-left"}
            `}
        >{props.text}</h1>
    )
}