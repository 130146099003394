import React from 'react';
import { TBackendKpiNetwork, TBackendNetworkNames } from '../../types/backend-data';
import { formatElectricity, formatEmissions, getSuperscriptUnicodeNumber } from '../../pages/MainDashboard/mainDashboardHelpers';
import { Heading } from '../../components/Heading';
import { getIconElement } from '../../icons';
import { styleConstants } from '../../style-constants';
import { Paragraph, TParagraphLinkPropsPartial } from '../../components/Paragraph';
import PolygonLogo from "../../assets/logos/polygon-primary.png"
import GerIcon from "../../assets/images/county-icons/ger.png";
import UsaIcon from "../../assets/images/county-icons/usa.png";




interface IComparisonSectionProps {
    kpiData: TBackendKpiNetwork;
    networkName: TBackendNetworkNames;
}

export const ComparisonSection: React.FC<IComparisonSectionProps> = (props) => {

    const householdConsumption = 10000
    const flightEmission = 6
    const usCitizen = 45.15
    const phoneCharge = 14.60


    const renderComparisonCards = () => {

        const createIconGroup = (mainIcon: JSX.Element, usSymbol: boolean, gerSymbol: boolean) => {
            const countryIconContainerStyle = "w-[28px] h-[17px]"
            const countryIconStyle = "w-full h-full object-fill"

            return (
                <div className='flex flex-row gap-x-[10px] items-center justify-center'>
                    {usSymbol && <div className={countryIconContainerStyle}>
                        <img
                            className={countryIconStyle}
                            src={UsaIcon}
                            alt='USA'
                            title='United States'
                        />
                    </div>}
                    <div className='h-8'>
                        {mainIcon}
                    </div>
                    {gerSymbol && <div className={countryIconContainerStyle}>
                        <img
                            className={countryIconStyle}
                            src={GerIcon}
                            alt='GER'
                            title='Germany'
                        />
                    </div>}
                </div>
            )
        }

        type TComparisonCardInfo = {
            valueFormatted: string,
            comparisonValueFormatted: string,
            description: string,
            footNote?: TParagraphLinkPropsPartial,
            icons: JSX.Element
            validForZkEvm: boolean
        }

        const calculateComparisonMetric = (metric1: number, metric2: number) => {
            return (metric1 / metric2).toFixed(0);
        }

        const getComparisonCardsInfo = (): TComparisonCardInfo[] => {

            const comparisonCardsAllNetworks = [
                {
                    valueFormatted: formatEmissions(props.kpiData["emissions365"].data, 0),
                    comparisonValueFormatted: formatEmissions(flightEmission, 0),
                    description: `The annualized carbon emissions of the Polygon network equals ${calculateComparisonMetric(props.kpiData["emissions365"].data, flightEmission)} roundtrip flights MUC-SFO in Business Class.`,
                    icons: createIconGroup(getIconElement('aircraft', "text-hoverPurple", undefined, undefined, "0 0 122.88 107.54", styleConstants.hoverPurple), true, true),
                    validForZkEvm: true
                },
                {
                    valueFormatted: formatElectricity(props.kpiData["electricity365"].data, 0),
                    comparisonValueFormatted: formatElectricity(householdConsumption, 1),
                    description: `The annualized electricity consumption of the Polygon network equals ${calculateComparisonMetric(props.kpiData["electricity365"].data, householdConsumption)} average US households.`,
                    icons: createIconGroup(getIconElement('household', "text-hoverPurple", undefined, undefined, "0 0 122.88 114.45", styleConstants.hoverPurple), true, false),
                    validForZkEvm: true
                },
            ]

            if (props.networkName === "zkevm") {

                return comparisonCardsAllNetworks

            } else {

                const comparisonCardsPoSOnly = [
                    {
                        valueFormatted: formatEmissions(props.kpiData["emissionsTx"].data, 4, 4, true),
                        comparisonValueFormatted: formatEmissions(usCitizen, 0, 0, false, "kgCO\u2082e"),
                        description: `An average US citizen emits about 45.15${getSuperscriptUnicodeNumber(1)} kg CO\u2082 per day. ${calculateComparisonMetric((usCitizen / 1000), props.kpiData["emissionsTx"].data)} million transactions on the Polygon PoS network cause the same amount of CO\u2082.`,
                        footNote: {
                            links: ["https://www.iea.org/commentaries/the-world-s-top-1-of-emitters-produce-over-1000-times-more-co2-than-the-bottom-1"],
                            linkTexts: ["Source"],
                            text: [`${getSuperscriptUnicodeNumber(1)}: Own calculations based on IEA (2023), The world's top 1% of emitters produce over 1000 times more CO2 than the bottom 1%, IEA, Paris (`, "), License: CC BY 4.0"],
                        },
                        icons: createIconGroup(getIconElement('person', "text-hoverPurple", undefined, undefined, "0 0 122.88 122.88", styleConstants.hoverPurple), true, false),
                        validForZkEvm: false
                    },
                    {
                        valueFormatted: formatElectricity(props.kpiData["electricityTx"].data, 4, 4, true),
                        comparisonValueFormatted: formatElectricity(phoneCharge, 1, undefined, undefined, "Wh"),
                        description: `One charge of a high-end smart phone${getSuperscriptUnicodeNumber(2)} consumes as much electricity as ${calculateComparisonMetric(phoneCharge, props.kpiData["electricityTx"].data)} Polygon PoS transactions.`,
                        footNote: {
                            links: ["https://www.iea.org/commentaries/the-world-s-top-1-of-emitters-produce-over-1000-times-more-co2-than-the-bottom-1"],
                            linkTexts: ["Source"],
                            text: [`${getSuperscriptUnicodeNumber(2)}: Assuming a battery of 12.7 Wh and an average charger efficiency of 87% (`, "), one phone charge of a high-end smartphone consumes about 14.60 Wh"],
                        },
                        icons: createIconGroup(getIconElement('phone', "text-hoverPurple", undefined, undefined, "0 0 71.31 122.88", styleConstants.hoverPurple), false, false),
                        validForZkEvm: false
                    }
                ]

                const allComparisonCards = comparisonCardsAllNetworks.concat(comparisonCardsPoSOnly)
                return allComparisonCards

            }
        }

        const cardStyle = `${styleConstants.boxGridLayout} ${styleConstants.boxStyle} flex-col gap-y-4`
        const cardRowStyle = "flex sm:flex-row flex-col items-center sm:justify-around sm:gap-y-0 gap-y-6 w-full"
        const cardMainRowColumnStyle = "flex flex-col justify-center items-center w-1/2 gap-2 w-[120px]"
        const cardNoteRowStyle = "flex justify-center flex-col gap-y-2"

        const comparisonCards = getComparisonCardsInfo().reduce((comparisonCardsAcc: JSX.Element[], cardInfo: TComparisonCardInfo, index: number) => {
            if (!(props.networkName === "zkevm" && false === cardInfo.validForZkEvm)) {
                const cardElem = (
                    <div className={cardStyle} key={`&{comparison-card-${index}}`}>
                        <div className={cardRowStyle}>

                            <div className={cardMainRowColumnStyle}>
                                <img
                                    src={PolygonLogo}
                                    alt="Polygon"
                                    className="w-32"
                                />
                                <Paragraph
                                    content={`~ ${cardInfo.valueFormatted}`}
                                    isWhiteText
                                    isCenter
                                    isThinText
                                />
                            </div>
                            <div className={cardMainRowColumnStyle}>
                                {cardInfo.icons}
                                <Paragraph
                                    content={`~ ${cardInfo.comparisonValueFormatted}`}
                                    isWhiteText
                                    isCenter
                                    isThinText
                                />
                            </div>
                        </div>
                        <div className={cardNoteRowStyle}>
                            <Paragraph
                                content={cardInfo.description}
                                isCenter
                                isCaption
                            />
                            {
                                cardInfo.footNote && (
                                    <Paragraph
                                        content={cardInfo.footNote}
                                        isLinkNotBold
                                        isLinkNotUnderline
                                        isCenter
                                        isCaption
                                    />
                                )
                            }
                        </div>
                    </div>
                )
                comparisonCardsAcc.push(cardElem)
            }
            return comparisonCardsAcc
        }, [])

        return (
            <div className={`${styleConstants.boxGridLayoutContainer} lg:flex-wrap`}>
                {comparisonCards}
            </div>
        )
    }

    const renderComponent = () => {

        return (
            <div
                className='flex flex-col items-center w-full'
            >
                <Heading
                    text={'Comparison of our Electricity and Carbon Footprint'}
                    isSubheading
                    hasMediumSmallBottomPadding
                    isSmTextCenter
                />
                <div className='flex flex-wrap justify-center w-full m-2'>
                    {renderComparisonCards()}
                </div>
            </div>
        )
    }

    return (
        renderComponent()
    )
};

