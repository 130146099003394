import { TBackendKpiNetwork, TBackendMapData, TBackendNetworkNames, TMetrics } from "../../types/backend-data"

export const getDataFromRecord = (input: Record<string, any>) => {
    return input["data"]
}


export const getAgeFromRecord = (input: Record<string, any>) => {
    return input["age"]
}


export const weiToMatic = (wei: number): number => {
    const maticValue = wei / 10 ** 18;
    return maticValue;
}


export const getUnits = (inputMetric: TMetrics, smallUnit?: boolean, customUnit?: string) => {
    if (customUnit) {
        return customUnit
    }

    if (inputMetric === "electricity") {
        return `${smallUnit ? "Wh" : "kWh"}`
    } else if (inputMetric === "emissions") {
        return `${smallUnit ? "g" : "t"}CO\u2082e`
    } else {
        //CO2 intensity
        return "kgCO\u2082e/kWh"
    }
}


export const getL2ShareUnit = () => {
    return "%"
}


export const formatNetworkName = (network: TBackendNetworkNames) => {
    const formattedNames: { [key in TBackendNetworkNames]: string } = {
        pos: "Polygon PoS",
        zkevm: "Polygon zkEVM",
        eth: "Ethereum"
    }
    return formattedNames[network]
}


export const formatEmissionElectricityNumber = (number: number, type: TMetrics, maximumFractionDigits?: number, minimumFractionDigits?: number, smallUnit?: boolean, customUnit?: string, customUnitFactor?: number): string => {

    if (customUnit && customUnitFactor) {
        number = number * customUnitFactor
    }

    const formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: minimumFractionDigits === undefined ? 0 : minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits === undefined ? 3 : maximumFractionDigits,
    }).format(number);

    const formattedNumberWithUnit = `${formattedNumber} ${getUnits(type, smallUnit, customUnit)}`
    return formattedNumberWithUnit
}


export const formatEmissions = (number: number, maximumFractionDigits?: number, minimumFractionDigits?: number, smallUnit?: boolean, customUnit?: string, customUnitFactor?: number): string => {
    return formatEmissionElectricityNumber(number, "emissions", maximumFractionDigits, minimumFractionDigits, smallUnit, customUnit, customUnitFactor)
}


export const formatElectricity = (number: number, maximumFractionDigits?: number, minimumFractionDigits?: number, smallUnit?: boolean, customUnit?: string, customUnitFactor?: number): string => {
    return formatEmissionElectricityNumber(number, "electricity", maximumFractionDigits, minimumFractionDigits, smallUnit, customUnit, customUnitFactor)
}


export const formatIntensity = (number: number, maximumFractionDigits?: number, minimumFractionDigits?: number, smallUnit?: boolean): string => {
    return formatEmissionElectricityNumber(number, "intensity", maximumFractionDigits, minimumFractionDigits, smallUnit)
}


export const isMapDataAvailable = (selectedNetwork: TBackendNetworkNames, mapData: TBackendMapData) => {
    return mapData[selectedNetwork] ? true : false
}

export const showAllocationSection = (selectedNetwork: TBackendNetworkNames) => {
    return selectedNetwork === "zkevm" ? false : true
}


export const formatEmissionsDynamic = (number: number, fractionDigits?: number) => {
    if (number < 1000) {
        return number.toFixed(fractionDigits) + ' gCO\u2082e';
    } else if (number < 1000000) {
        return (number / 1000).toFixed(fractionDigits) + ' kgCO\u2082e';
    } else {
        return (number / 1000000).toFixed(fractionDigits) + ' tCO\u2082e';
    }
}


export const getMostOutdatedKpiAge = (kpiData: TBackendKpiNetwork) => {
    let mostOutdatedAge = new Date(); // Initialize with the current date and time

    for (const key in kpiData) {
        if (kpiData.hasOwnProperty(key)) {
            const item = kpiData[key as keyof TBackendKpiNetwork];
            if (item) {
                const itemAge = new Date(getAgeFromRecord(item));

                if (itemAge < mostOutdatedAge) {
                    mostOutdatedAge = itemAge;
                }
            }
        }
    }

    return mostOutdatedAge.toString();
}


export const getSuperscriptUnicodeNumber = (number: number) => {
    
    const superscriptNumbers: {[key: number]: string} = {
        1: "\u00B9",
        2: "\u00B2",
        3: "\u00B3",
        4: "\u2074",
        5: "\u2075",
        6: "\u2076",
    }
    // Check if the input is within the range [1, 5]
    if (number < 1 || number > 6) {
      return "";
    }else {
        return superscriptNumbers[number]
    }
  
  }