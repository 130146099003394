import React from 'react';
import { TBackendKpiNetwork, TBackendNetworkNames, TBackendNetworkTickers, TMetrics } from '../../types/backend-data';
import { formatEmissionElectricityNumber, formatNetworkName, getAgeFromRecord, getDataFromRecord, getMostOutdatedKpiAge } from '../../pages/MainDashboard/mainDashboardHelpers';
import { OutdatedAlert } from '../../components/OutdatedAlert';
import { styleConstants } from '../../style-constants';
import { Heading } from '../../components/Heading';
import { Paragraph } from '../../components/Paragraph';
import { InfoTooltip } from '../../components/InfoTooltip';
import { useAppSelector } from '../../state/hooks';
import { getIconElement } from '../../icons';

interface IKpiSectionProps {
    ticker: TBackendNetworkTickers
    kpiData: TBackendKpiNetwork
}

export const KpiSection: React.FC<IKpiSectionProps> = (props) => {

    const selectedNetworkName = useAppSelector<TBackendNetworkNames>(state => state.dashboard.selectedNetwork)


    const getLabelForKey = (key: keyof TBackendKpiNetwork) => {
        const kpiLabels = {
            "intensity": "CO\u2082 intensity",
            "emissions365": "Emissions (annualized)",
            "electricity365": "Electricity (annualized)",
            "emissionsHolding": `Emissions per 1 ${props.ticker}`,
            "emissionsTx": "Emissions per transaction",
            "electricityHolding": `Electricity per 1 ${props.ticker}`,
            "electricityTx": "Electricity per transaction",
        } as Record<keyof TBackendKpiNetwork, string>;
        if (kpiLabels[key]) {
            return kpiLabels[key]
        } else {
            return key
        }
    }

    const getValueForKey = (key: keyof TBackendKpiNetwork, type: TMetrics, smallUnit: boolean, digits: number) => {
        const singleKpiData = props.kpiData[key]
        if (singleKpiData) {
            const value = getDataFromRecord(props.kpiData[key])
            return formatEmissionElectricityNumber(value, type, digits, digits, smallUnit)
        } else {
            return undefined
        }
    }

    const renderKpiCards = () => {
        const kpiCardsContent: [keyof TBackendKpiNetwork, TMetrics, boolean, number, string?][] = [
            ["emissions365", "emissions", false, 2],
            ["electricity365", "electricity", false, 2],
            ["emissionsHolding", "emissions", true, 5, "Holding 1 Matic for one day results in this amount of emissions."],
            ["emissionsTx", "emissions", true, 5],
            ["electricityHolding", "electricity", true, 5, "Holding 1 Matic for one day consumes this amount of electricity."],
            ["electricityTx", "electricity", true, 5],
            ["intensity", "intensity", false, 3, "Emission intensity factor based on locations of validators."],
        ]

        type TKpiCardsContentAccumulator = {
            electricityKpis: JSX.Element[];
            otherKpis: JSX.Element[];
            electricityCount: number;
            otherCount: number;
        }

        const groupedKpiCards = kpiCardsContent.reduce<TKpiCardsContentAccumulator>(
            (result, kpiElem) => {

                const isElectricityKpi = kpiElem[1] === "electricity";
                const tooltipText = kpiElem[4]
                const mobileTextSizeClass = "text-sm"
                const desktopTextSizeClasses = "text-sm sm:text-base"

                const label = getLabelForKey(kpiElem[0])
                const value = getValueForKey(
                    kpiElem[0],
                    kpiElem[1],
                    kpiElem[2],
                    kpiElem[3]
                )

                const renderValueElem = (value: string | undefined, isMobile: boolean) => {

                    const isUnavailableValue = undefined === value
                    const elem = (
                        <div className='flex flex-row gap-x-2'>
                            <Paragraph
                                content={`${isUnavailableValue ? "-" : value}`}
                                isWhiteText
                                isThinText
                                isCenter={isMobile}
                                customStyle={`flex items-center ${isMobile ? mobileTextSizeClass : desktopTextSizeClasses}`}
                            />
                            {
                                isUnavailableValue && (
                                    <InfoTooltip
                                        text='Allocation data is currently unavailable.'
                                        type='info'
                                    />
                                )
                            }
                        </div>
                    )
                    return elem
                }

                const singleKpiDiv = (
                    <div key={`kpi-${result.electricityCount + result.otherCount}`}>
                        <div className='flex flex-col sm:flex-row w-full justify-center items-center gap-y-1 sm:gap-y-0 sm:justify-between'>
                            <div className='flex gap-2 w-full sm:w-auto justify-center sm:justify-normal'>
                                <div className={styleConstants.mobileClasses}>
                                    <Paragraph
                                        content={`${label}:`}
                                        isWhiteText
                                        isThinText
                                        isCenter
                                        customStyle={`w-auto sm:w-fit ${mobileTextSizeClass}`}
                                    />
                                </div>
                                <div className={styleConstants.desktopClasses}>
                                    <Paragraph
                                        content={`${label}:`}
                                        isWhiteText
                                        isThinText
                                        customStyle={`w-auto sm:w-fit ${desktopTextSizeClasses}`}
                                    />
                                </div>


                                {tooltipText && <div className='w-[20px] flex items-center'>
                                    <InfoTooltip text={tooltipText} type='info' />
                                </div>}
                            </div>

                            <div className={styleConstants.mobileClasses}>
                                {renderValueElem(value, true)}
                            </div>
                            <div className={styleConstants.desktopClasses}>
                                {renderValueElem(value, false)}
                            </div>

                        </div>
                    </div>
                );

                if (isElectricityKpi) {
                    result.electricityKpis.push(singleKpiDiv);
                    result.electricityCount++;
                } else {
                    result.otherKpis.push(singleKpiDiv);
                    result.otherCount++;
                }

                return result;
            },
            { electricityKpis: [], otherKpis: [], electricityCount: 0, otherCount: 0 }
        );

        const createKpiBox = (heading: string, icon: JSX.Element, kpiElements: JSX.Element[]) => {
            const kpiGroupContainerStyle = `${styleConstants.boxGridLayout} ${styleConstants.boxStyle}`
            const kpiGroupStyle = `flex justify-center flex-col h-full gap-4 sm:gap-2 mt-4`
            return (
                <div className={kpiGroupContainerStyle}>
                    <div className='flex flex-col sm:flex-row items-center gap-y-2 sm:gap-y-0 gap-x-3'>
                        <div className='w-6'> {icon} </div>
                        <Heading
                            text={heading}
                            isSubheading={true}
                        />
                    </div>
                    <div className={kpiGroupStyle}>
                        {kpiElements}
                    </div>
                    <div className='flex justify-center sm:justify-end'>
                        <OutdatedAlert
                            timestamp={getMostOutdatedKpiAge(props.kpiData)}
                            hasTopMargin
                        />
                    </div>
                </div>
            )
        }

        return (
            <div className='flex w-full flex-col items-center'>
                <Heading
                    text={`Infrastructure of ${formatNetworkName(selectedNetworkName)}`}
                    isSubheading
                    hasMediumSmallBottomPadding
                    isSmTextCenter
                />
                <div className={styleConstants.boxGridLayoutContainer}>
                    {createKpiBox("Carbon Footprint", getIconElement('emissions', 'text-white'), groupedKpiCards.otherKpis)}
                    {createKpiBox("Electricity Consumption", getIconElement('electricity', 'text-white'), groupedKpiCards.electricityKpis)}
                </div>
            </div>

        );
    };


    return (
        renderKpiCards()
    )
};
