import { Heading } from "../../components/Heading"
import { PageTransitionWrapper } from "../../components/PageTransitionWrapper"
import { styleConstants } from "../../style-constants"
import { useAppDispatch, useAppSelector } from "../../state/hooks"
import { TBackendKpi, TBackendKpiNetwork, TBackendNetworkNames, TBackendTicker, TMetrics } from "../../types/backend-data"
import { useCallback, useEffect, useState } from "react"
import { isAllMicaDataLoaded, loadUnavailableMicaData } from "../../state/reducers/dashboardReducerHelpers"
import { setSelectedNetwork } from "../../state/actions/dashboardActions"
import { LoadingSpinner } from "../../components/LoadingSpinner"
import { NetworkSelector } from "../../components/NetworkSelector"
import { BackendOfflineAlert } from "../../components/BackendOfflineAlert"
import { Transition } from "@headlessui/react"
import { Paragraph } from "../../components/Paragraph"
import { formatEmissionElectricityNumber, formatEmissions, formatNetworkName, getDataFromRecord, getMostOutdatedKpiAge, getSuperscriptUnicodeNumber } from "../MainDashboard/mainDashboardHelpers"
import { TMicaTableContent } from "../../types/component-types"
import { InfoTooltip } from "../../components/InfoTooltip"
import { getIconElement } from "../../icons"
import { PoweredByCcri } from "../../components/PoweredByCcri"
import { OutdatedAlert } from "../../components/OutdatedAlert"


interface IMicaProps {

}


export const Mica: React.FC<IMicaProps> = (props) => {


  const dispatch = useAppDispatch()

  const [micaLoading, setMicaLoading] = useState<boolean>(true);
  const [tableContent, setTableContent] = useState<TMicaTableContent[]>([]);

  const tickers = useAppSelector<TBackendTicker>(state => state.dashboard.tickers);
  const kpiData = useAppSelector<TBackendKpi>(state => state.dashboard.kpiData);
  const selectedNetworkName = useAppSelector<TBackendNetworkNames>(state => state.dashboard.selectedNetwork)
  const isBackendOffline = useAppSelector<boolean>(state => state.session.isBackendOffline)

  const tableColNames = ["Type", "Adverse Sustainability Indicator", formatNetworkName(selectedNetworkName), "ETH Network", "Metric Description"]

  const getValueForKeyAndFormat = (data: TBackendKpiNetwork, key: keyof TBackendKpiNetwork, type: TMetrics, maximumFractionDigits?: number, minimumFractionDigits?: number, customUnit?: string, customUnitFactor?: number) => {
    const record = data[key]
    if (record) {
      const value = getDataFromRecord(record)
      return formatEmissionElectricityNumber(value, type, maximumFractionDigits, minimumFractionDigits, undefined, customUnit, customUnitFactor)
    } else {
      return ""
    }
  }



  const generateTableContent = useCallback((): TMicaTableContent[] => {

    const iconStrokeWidth = 1.5
    const iconTextColor = "boxBackgroundGray md:text-hoverPurple"
    const perTxFractionDigits = 6

    const polygonTableData: TBackendKpiNetwork = kpiData[selectedNetworkName]
    const ethereumTableData: TBackendKpiNetwork = kpiData["eth"]

    const isZkevm = selectedNetworkName === "zkevm"

    return [
      {
        type: "Energy",
        icon: getIconElement('electricity', iconTextColor, iconStrokeWidth),
        indicator: "Energy consumption",
        polygonValue: getValueForKeyAndFormat(polygonTableData, "electricity365", "electricity", 0),
        ethereumValue: getValueForKeyAndFormat(ethereumTableData, "electricity365", "electricity", 0),
        info: "Total amount of energy used, expressed in kilowatt-hours (kWh) per calendar year, for the validation of transactions and the maintenance of the integrity of the distributed ledger of transactions.",
      },
      {
        type: undefined,
        icon: undefined,
        indicator: `Non-renewable energy consumption${getSuperscriptUnicodeNumber(2)}`,
        polygonValue: isZkevm ? "" : "79.6 %",
        ethereumValue: "68.4 %",
        info: "Share of energy used generated from nonrenewable sources, expressed as a percentage of the total amount of energy used per calendar year, for the validation of transactions and the maintenance of the integrity of the distributed ledger of transactions.",
      },
      {
        type: undefined,
        icon: undefined,
        indicator: "Energy intensity",
        polygonValue: getValueForKeyAndFormat(polygonTableData, "electricityTx", "electricity", perTxFractionDigits, perTxFractionDigits, "kWh", 1 / 1000),
        ethereumValue: getValueForKeyAndFormat(ethereumTableData, "electricityTx", "electricity", perTxFractionDigits, perTxFractionDigits, "kWh", 1 / 1000),
        info: "Average amount of energy used, in kWh, per validated transaction.",
      },
      {
        type: "GHG Emissions",
        icon: getIconElement('emissions', iconTextColor, iconStrokeWidth),
        indicator: "Scope 1 - Controlled",
        polygonValue: formatEmissions(0, 0),
        ethereumValue: formatEmissions(0, 0),
        info: "Scope 1 GHG emissions, expressed in tonnes (t) carbon dioxide equivalent (CO\u2082e) per calendar year for the validation of transactions and the maintenance of the integrity of the distributed ledger of transactions.",
      },
      {
        type: undefined,
        icon: undefined,
        indicator: "Scope 2 - Purchased",
        polygonValue: getValueForKeyAndFormat(polygonTableData, "emissions365", "emissions", 2, 2),
        ethereumValue: getValueForKeyAndFormat(ethereumTableData, "emissions365", "emissions", 2, 2),
        info: "Scope 2 GHG emissions, expressed in tCO\u2082e per calendar year for the validation of transactions and the maintenance of the integrity of the distributed ledger of transactions.",
      },
      {
        type: undefined,
        icon: undefined,
        indicator: "GHG intensity",
        polygonValue: getValueForKeyAndFormat(polygonTableData, "emissionsTx", "emissions", perTxFractionDigits, perTxFractionDigits, "kgCO\u2082e", 1 / 1000),
        ethereumValue: getValueForKeyAndFormat(ethereumTableData, "emissionsTx", "emissions", perTxFractionDigits, perTxFractionDigits, "kgCO\u2082e", 1 / 1000),
        info: "Average GHG emissions (scope 1 and scope 2) per validated transaction, expressed in kilogram (kg) CO\u2082e per transaction (Tx).",
      },
      {
        type: "Waste Production",
        icon: getIconElement('waste', iconTextColor, iconStrokeWidth),
        indicator: `Generation of waste electrical and electronic equipment (WEEE)${getSuperscriptUnicodeNumber(3)}`,
        polygonValue: isZkevm ? "" : "805.34 kg",
        ethereumValue: "51150.17 kg",
        info: "Total amount of WEEE generated for the validation of transactions and the maintenance of the integrity of the distributed ledger of transactions, expressed in tonnes per calendar year.",
      },
      {
        type: undefined,
        icon: undefined,
        indicator: `Non-recycled WEEE ratio${getSuperscriptUnicodeNumber(4)}`,
        polygonValue: isZkevm ? "" : "81 %",
        ethereumValue: "68.1 %",
        info: "Share of the total amount of WEEE generated for the validation of transactions and the maintenance of the integrity of the distributed ledger of transactions, not recycled per calendar year, expressed as a percentage",
      },
      {
        type: undefined,
        icon: undefined,
        indicator: `Generation of hazardous waste${getSuperscriptUnicodeNumber(5)}`,
        polygonValue: isZkevm ? "" : "0.00015 t",
        ethereumValue: "0.00956 t",
        info: "Total amount of hazardous waste generated for the validation of transactions and the maintenance of the integrity of the distributed ledger of transactions, expressed in tonnes per calendar year."
      },
      {
        type: "Natural Resources",
        icon: getIconElement('leaf', iconTextColor, iconStrokeWidth),
        indicator: "Impact of the use of equipment on natural resources",
        polygonValue: `The impact on natural resources such as water, fossil fuels and critical raw materials of the production, the use and the disposal of the devices of the DLT network nodes is heavily driven by the amount of energy consumed by the network and amount of hardware required. Given that the amount of energy and e-waste required by Polygon PoS and Ethereum is only a fraction of PoW-based networks, this also applies to the impact on natural resources (see e.g., Chamanara et al. 2023${getSuperscriptUnicodeNumber(6)} for an overview of Bitcoin's environmental footprint including water consumption). We will provide a detailed assessment of the impact on natural resources of Polygon PoS and Ethereum soon.`,
        ethereumValue: "",
        info: "Description of the impact on natural resources of the production, the use and the disposal of the devices of the DLT network nodes.",
      },
    ]
  }, [kpiData, selectedNetworkName]);


  const renderComingSoonAlert = () => {
    return (
      <div className="flex items-center justify-center gap-x-1">
        <div className="w-3">
          {getIconElement("workInProgress", "text-boxBackgroundGray")}
        </div>
        <Paragraph
          content={"coming soon"}
        />
      </div>
    )
  }


  const isTableTextCenter = (colIndex: number) => {
    return (colIndex === 1) ? false : true
  }


  const createInfoTooltipWithContainer = (tooltipText: string, wideTooltip?: boolean) => {
    return (
      <div className="flex justify-center items-center">
        <div className="absolute">
          <InfoTooltip
            text={tooltipText}
            type="info"
            wideTooltip={wideTooltip ? true : false}
            customIconHeight={"h-[20px]"}
            customTooltipStyle="!bg-opacity-85"
          />
        </div>
      </div>
    )
  }

  const isNaturalResourcesRow = (rowInfo: TMicaTableContent) => {
    return rowInfo.type === "Natural Resources"
  }



  const renderDesktopTable = () => {

    const renderDesktopTableHead = () => {
      const thClasses = "px-3 py-3.5 text-sm text-gray-900"
      const paragraphClasses = "!font-bold text-boxBackgroundGray"
      const colWidthClasses = [
        "min-w-[100px]",
        "w-auto",
        "min-w-[125px]",
        "min-w-[125px]",
        "min-w-[50px]",
      ]
      const tableHeads = tableColNames.map((name, index) => {
        return (
          <th scope="col" className={`${thClasses} ${colWidthClasses[index]}`} key={`head-col-${index}`}>
            <Paragraph
              content={name}
              isCenter={isTableTextCenter(index)}
              customStyle={paragraphClasses}
            />
          </th>
        )
      })
      return (
        <thead className="bg-slate-100">
          <tr>
            {tableHeads}
          </tr>
        </thead>
      )
    }

    const renderDesktopTableBody = () => {

      const renderValue = (value: string) => {
        return (
          value ?
            <Paragraph
              content={value}
              isThinText
              isCenter
              customStyle={tableContentTextCenterClass}
            />
            :
            renderComingSoonAlert()
        )
      }

      const tdClasses = "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
      const tableContentTextCenterClass = "text-center"
      const tableRows = tableContent.map((rowInfo: TMicaTableContent, index: number) => {
        const hasRowFirstMultilineCol = rowInfo.type && rowInfo.icon
        return (
          <tr key={`body-row-${index}`} >
            {hasRowFirstMultilineCol &&
              <td className={`${tdClasses} font-medium`} rowSpan={3}>
                <div className="w-full flex flex-col justify-center items-center gap-y-2">
                  <div className="w-7">
                    {rowInfo.icon!}
                  </div>
                  <Paragraph
                    content={rowInfo.type!}
                    customStyle={`!text-hoverPurple font-semibold ${tableContentTextCenterClass}`}
                  />
                </div>
              </td>
            }
            <td className={tdClasses}>
              <Paragraph
                content={rowInfo.indicator}
                isThinText
              />
            </td>
            {
              isNaturalResourcesRow(rowInfo) ?
                <>
                  <td
                    className={tdClasses}
                    colSpan={2}
                  >
                    {createInfoTooltipWithContainer(rowInfo.polygonValue, true)}
                  </td>
                </>
                :
                <>
                  <td className={tdClasses}>
                    {renderValue(rowInfo.polygonValue)}

                  </td>
                  <td className={tdClasses}>
                    {renderValue(rowInfo.ethereumValue)}
                  </td>
                </>
            }
            <td className={`${tdClasses} h-full`}>
              {createInfoTooltipWithContainer(rowInfo.info)}
            </td>
          </tr>
        )
      })
      return (
        <tbody className="divide-y divide-gray-200 bg-white">
          {tableRows}
        </tbody>
      )


    }

    return (
      <>
        {renderDesktopTableHead()}
        {renderDesktopTableBody()}
      </>
    )
  }

  const renderMobileTable = () => {

    const createMobileTableKeyValuePair = (key: string, value: string): JSX.Element => {
      return (
        <div className="flex flex-col justify-center items-center">
          <Paragraph
            isCenter
            content={key}
          />
          {
            value ?
              <Paragraph
                content={value}
                isCenter
                isThinText
              /> :
              renderComingSoonAlert()
          }
        </div>
      )
    }

    const createMobileInfoTooltipWithContainer = (metricHeading: string, tooltipText: string) => {
      return (
        <div className="flex flex-col gap-y-3">
          <Paragraph
            content={metricHeading}
            isCenter
          />
          {createInfoTooltipWithContainer(tooltipText, true)}
        </div>
      )
    }

    const filteredRows: JSX.Element[] = tableContent.reduce<JSX.Element[]>((rowElementsAccumulator, rowInfo: TMicaTableContent, index: number) => {
      // Only include the element if it has both type and icon
      if (rowInfo.type && rowInfo.icon) {
        const rowTypeElement = (
          <tr key={`table-${index}-type`} className="bg-gray-200">
            <td className={`font-medium text-gray-900`}>
              <div className="w-full flex flex-row justify-center items-center gap-x-2 my-6">
                <div className="w-7">
                  {rowInfo.icon!}
                </div>
                <Paragraph
                  content={rowInfo.type!}
                  customStyle={`!font-semibold !text-boxBackgroundGray`}
                />
              </div>
            </td>
          </tr>
        )
        rowElementsAccumulator.push(rowTypeElement);
      }
      const rowRegularElement = (
        <tr key={`table-${index}`} className="bg-white">
          <td className="flex flex-col justify-center items-center gap-y-4 mx-2 xxs:mx-4 my-4 text-gray-500">
            <div className="w-full xs:w-2/3">
              <Paragraph
                content={rowInfo.indicator}
                isCenter
                customStyle={"!font-bold"}
              />
            </div>
            <div className="flex flex-col justify-center items-center gap-y-4 mx-2 my-2">
              {
                isNaturalResourcesRow(rowInfo) ?
                  <>
                    {createMobileInfoTooltipWithContainer(`${tableColNames[2]} / ${tableColNames[3]}`, rowInfo.polygonValue)}
                  </>
                  :
                  <>
                    {createMobileTableKeyValuePair(tableColNames[2], rowInfo.polygonValue)}
                    {createMobileTableKeyValuePair(tableColNames[3], rowInfo.ethereumValue)}
                  </>
              }
              {
                createMobileInfoTooltipWithContainer(tableColNames[4], rowInfo.info)
              }
            </div>
          </td>
        </tr>
      )
      rowElementsAccumulator.push(rowRegularElement);
      return rowElementsAccumulator;
    }, []);

    const mobileTable = (
      <tbody className="divide-y divide-gray-200">
        {filteredRows}

      </tbody>
    )

    return mobileTable
  }

  const renderTable = () => {

    const wrapTableInContainer = (jsxTable: JSX.Element) => {
      return (
        <div className={`${styleConstants.roundedBorder} overflow-hidden w-full`}>
          <table className="min-w-full h-full rounded-xl border border-tools-table-outline divide-y divide-gray-300 ">
            {jsxTable}
          </table >
        </div >
      )

    }

    return (
      <>
        <div className={`${styleConstants.desktopMdClasses}`}>
          {wrapTableInContainer(renderDesktopTable())}
        </div >
        <div className={`${styleConstants.mobileMdClasses}`}>
          {wrapTableInContainer(renderMobileTable())}
        </div >
      </>
    )
  }

  const renderMicaTable = () => {
    return (
      <div className="flex flex-col w-full max-w-[1150px]">
        <div className="flex justify-center md:justify-end mb-4 md:mr-2">
          <PoweredByCcri />
        </div>
        {renderTable()}
        <div className="flex justify-center md:justify-end md:mr-2">
          <OutdatedAlert
            timestamp={getMostOutdatedKpiAge(kpiData[selectedNetworkName])}
            hasTopMargin
          />
        </div>
      </div>

    )
  }

  const renderTextSection = () => {

    const renderFootnotes = () => {

      const renderLinkFootnote = (number: number, link: string) => {
        return (
          <Paragraph
            content={{
              text: [`${getSuperscriptUnicodeNumber(number)} `],
              links: [link],
              linkTexts: [link],

            }}
            isCenter
            isCaption
            isLinkNotBold
          />
        )
      }

      const renderMiddleFootnoteGroup = () => {
        const footnotesText = [
          "We derive the values of the electricity sources of relevant countries and calculate a non-renewable energy share. Considering node locations and node counts, we are able to derive the total non-renewable energy consumption of the network. (Comparison: US, 79.3 %. Germany, 58.8 %.)",
          "Based on the hardware estimates, we calculate the total weight of the devices. We depreciate the hardware based on the length of the respective manufacturer guarantee and calculate an average annual waste over all hardware devices.",
          "We collect electronic waste recycling rates of countries. If data is unavailable, we use recycling rates from the respective regions. Considering node locations and node counts, we are able to derive the total share of non-recycled electronic waste of the network. (Comparison: US, 85.3 %. Germany, 47.9 %.)",
          "Based on our electronic waste calculation, we further analyzed hardware specifications of a single device and its hazardous waste components. We used the share of hazardous waste as a proxy for all other devices for which such information was not available and ignored components such as cases.",
        ]
        return footnotesText.map((text, index) => {
          const footnoteNumber = index + 2
          return (
            <div
              key={`mica-footnote-${footnoteNumber}`}
            >
              <Paragraph
                content={`${getSuperscriptUnicodeNumber(footnoteNumber)} ${text}`}
                isCenter
                isCaption
              />
            </div>
          )
        })
      }

      return (
        <div className="flex flex-col gap-y-2">
          {
            renderLinkFootnote(1, "https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32023R1114")
          }
          {
            renderMiddleFootnoteGroup()
          }
          {
            renderLinkFootnote(6, "https://agupubs.onlinelibrary.wiley.com/doi/10.1029/2023EF003871")
          }
        </div>
      )
    }

    return (
      <div className="flex flex-col w-full sm:w-2/3 gap-y-8">
        <div className="flex flex-col">
          <Paragraph
            content={"MiCA (Markets in Crypto-Asset) Regulation defines roles and responsibilities for entities within the crypto ecosystem. Among other requirements, CASPs (Crypto-Asset Service Providers) and Crypto-Asset Issuers are required to disclose sustainability metrics of currencies they offer. If you are a CASP, CCRI may help you by providing respective data for your website or in your application."}
            isCenter
          />
        </div>
        {renderFootnotes()}
      </div>
    )
  }


  const renderComponent = () => {

    const subheadingText = `ESMA's Consultation Package 2 proposes mandatory disclosure for a large set of quantitative climate and environment-related indicators.${getSuperscriptUnicodeNumber(1)}`;

    if (micaLoading) {
      return <LoadingSpinner />
    } else if (false === micaLoading && true === isBackendOffline) {
      return <BackendOfflineAlert />
    } else {
      return (
        <div
          className="flex flex-col w-full items-center gap-y-8 mb-8">
          <div className="flex flex-col w-full gap-y-16 mb-4">
            <div>
              <Heading
                text="MiCA Indicators"
                hasSmallBottomPadding
              />
              <div className={styleConstants.mobileClasses}>
                <Paragraph
                  content={subheadingText}
                  isCenter
                />
              </div>
              <div className={styleConstants.desktopClasses}>
                <Paragraph
                  content={subheadingText}
                />
              </div>
            </div>
            <div className="hidden">
              {/* TODO: remove surrounding div once mica values ready for zkevm */}
              <NetworkSelector />
            </div>
          </div>
          <Transition
            className={"w-full flex justify-center"}
            key={`mica-${selectedNetworkName}`}
            appear={true}
            show={true}
            enter={`${styleConstants.transitionStandard}`}
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave={`${styleConstants.transitionStandard}`}
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {
              renderMicaTable()
            }
          </Transition>
          {
            renderTextSection()
          }

        </div>
      )
    }
  }


  useEffect(() => {

    //load data if not yet loaded, on initial page load
    if (false === isAllMicaDataLoaded()) {
      setMicaLoading(true)
      loadUnavailableMicaData(dispatch)
    }

  }, [dispatch]);


  useEffect(() => {

    //check if all data there on each update, set loading to false if all are there, create table content if data are there
    if (true === isAllMicaDataLoaded()) {
      if (undefined === selectedNetworkName) {
        // on initial load, set selectedNetworkName to the first network
        const networkNames = Object.keys(tickers) as TBackendNetworkNames[]
        dispatch(setSelectedNetwork(networkNames[0]))
      }

      // set network to PoS if it was zkEVM before for now, since mica table not ready for zkEVM yet
      if (selectedNetworkName === "zkevm") {
        dispatch(setSelectedNetwork("pos"))
      }

      setMicaLoading(false)
    } else if (true === isBackendOffline) {
      setMicaLoading(false)
    }
  }, [dispatch, tickers, kpiData, isBackendOffline, selectedNetworkName]);


  useEffect(() => {

    //update the table data on initially (loading done) and on network change
    if (false === micaLoading) {
      setTableContent(generateTableContent())
    }

  }, [micaLoading, selectedNetworkName, generateTableContent]);

  return (
    <PageTransitionWrapper
      component={renderComponent()}
    />)
}