class PolygonscanTxNotFound extends Error {
    constructor() {
        const errorMessage = "polygonscanTxNotFound"
        super(errorMessage);
    }
}


export const customErrors = {
    noTxsFound: PolygonscanTxNotFound
}
