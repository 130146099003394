import React, { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react'
import { Button } from '../Button';
import { Paragraph } from '../Paragraph';
import { styleConstants } from '../../style-constants';


interface IAlertBoxProps {
    type: "success" | "warning" | "error"
    text: string;
    buttonText: string;
    onButtonClick: () => void;
}

export const AlertBox: React.FC<IAlertBoxProps> = (props) => {

    const [isVisible, setIsVisible] = useState<boolean>(false);

    const getBackgroundColor = () => {
        if (props.type === 'success') {
            return 'border-green-800';
        } else if (props.type === 'warning') {
            return 'border-yellow-800';
        } else if (props.type === 'error') {
            return 'border-red-800';
        }
    }

    useEffect(() => {
        setIsVisible(true);
        return () => {
            setIsVisible(false);
        };
    }, []);

    return (
        <Transition
            show={isVisible}
            enter={`${styleConstants.transitionStandard}`}
            enterFrom="opacity-0 scale-50"
            enterTo="opacity-100 scale-100"
            leave={`${styleConstants.transitionStandard}`}
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-50"
        >
            <div className={`w-fit flex items-center gap-x-8 rounded-lg bg-transparent border-2 p-4 ${getBackgroundColor()}`}>
                <Paragraph
                    content={props.text}
                    isWhiteText
                    isThinText
                />
                <Button buttonText={props.buttonText} onButtonClick={props.onButtonClick} />
            </div>
        </Transition>

    );
};
