import { Heading } from "../../components/Heading"
import { Paragraph, TParagraphContentType } from "../../components/Paragraph"
import { PageTransitionWrapper } from "../../components/PageTransitionWrapper"
import { styleConstants } from "../../style-constants"
import GreenManifestoImage from "../../assets/images/polygon-green-manifesto.webp"


interface IIMethodologyProps {

}


export const Manifesto: React.FC<IIMethodologyProps> = (props) => {


    const renderText = () => {

        const paragraphs: TParagraphContentType[] = [
            `When our children ask us what we were doing during the crucial decade when the future of life on Earth hung in the balance, will it be enough to say we were building a complete suite of Ethereum scaling solutions? Will all of our daring ambition, our hard work and ingenuity, our trials, tribulations and victories seem meaningful from the vantage point of the generation that comes after?`,
            `We think the answer can be “yes,” but we need to take steps now to make sure that it is. The Polygon Labs team deeply believes that Web3 is a transformative technology that will make the current Internet era seem like the Dark Ages. But we also recognize that in creating that future we cannot ignore the broader context of what technological progress has meant for the planet.`,
            `The geological scale of anthropogenic climate change can be almost incomprehensible to a mere human: waters rushing back to the coastlines as the colossal churn of the Gulf stream weakens its pull, thermal expansion of the oceans, days growing longer as gravity-driven liquid bulge at the equator slows the rotation of the Earth. The scope of the problem can be dispiriting, but we can start by doing what's possible.`,
            `That's why Polygon Labs has participated in various efforts to eliminate all of the carbon debt of the Polygon network going back to inception and now more carbon is retired through carbon removal projects each year than the Polygon network emits. This means that every transaction -- an NFT minted by an artist, a DeFi trade that empowers individual economic autonomy, a token bridged to a project building on the Polygon network -- is accounted for and its environmental impact is offset. Going forward, the goal is for the Polygon network to become the first blockchain network to reach net zero through collaboration between climate action leaders, technologists, scientists, and the Polygon community to decarbonize Polygon infrastructure.`,
            {
                text: ["You can read more about the plan to accomplish this ", ". Polygon Labs has also commissioned a third-party assessment of the carbon footprint of the Polygon network and the results can be found on the ", "."],
                links: ["https://go.polygon.technology/3uQHqme", "/methodology"],
                linkTexts: ["here", "methodology page"],
                linkTargets:[undefined, "_self"]
            },
            `Let's make meaningful change in the world!`,
        ]

        return (
            <div className="flex flex-col gap-8">
                <Heading
                    text="A Smart Contract with Planet Earth"
                    isSubheading
                    hasSmallBottomPadding
                />
                {
                    paragraphs.map((para, index) => (
                        <Paragraph
                            key={`para-${index}`}
                            content={para}
                            isWhiteText
                        />
                    ))
                }
            </div>)
    }

    const renderContent = () => {
        return (
            <div className={`${styleConstants.maxWidthTextPage} max-w-4xl w-full flex flex-col`}>
                <img
                    src={GreenManifestoImage}
                    alt="Polygon Green Manifesto"
                    className={`mb-16 rounded-3xl self-center h-auto w-full`}
                />

                {renderText()}

            </div>

        )
    }


    const renderComponent = () => {
        return (
            <div>
                <Heading
                    text="Our Green Manifesto"
                    hasBottomPadding
                />
                <div className="w-full flex flex-col justify-center items-center">
                    {
                        renderContent()
                    }
                </div>

            </div>
        )
    }


    return (
        <PageTransitionWrapper
            component={renderComponent()}
        />
    )
}