import React from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { styleConstants } from '../../style-constants';

interface ILoadingSpinnerProps {

}

export const LoadingSpinner: React.FC<ILoadingSpinnerProps> = (props) => {

  return (

    <div
      className='w-full flex items-center justify-center my-24'
    >
      <PropagateLoader
        color={styleConstants.hoverPurple}
        loading={true}
        size={20}
        data-testid="loader"
      />
    </div>

  )
};

