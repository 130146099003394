import { useEffect, useState } from "react";
import { styleConstants } from "../../style-constants";



interface IScrollFadingSectionWrapperProps {
    sections: JSX.Element[]
}


export const ScrollFadingSectionWrapper: React.FC<IScrollFadingSectionWrapperProps> = (props) => {

    const [visibleSections, setVisibleSections] = useState<boolean[]>(
        Array(props.sections.length).fill(false)
    );
    const [wasVisibleSections, setWasVisibleSections] = useState<boolean[]>(
        Array(props.sections.length).fill(false)
    );


    useEffect(() => {

        // Create intersection observer
        const observer = new IntersectionObserver(
            (entries) => {
                const newVisibleSections = [...visibleSections];
                entries.forEach((entry, index) => {
                    if (entry.isIntersecting) {
                        newVisibleSections[index] = true;
                        // Mark the section as "was visible" when it becomes visible for the first time
                        if (!wasVisibleSections[index]) {
                            const updatedWasVisibleSections = [...wasVisibleSections];
                            updatedWasVisibleSections[index] = true;
                            setWasVisibleSections(updatedWasVisibleSections);
                        }
                    } else {
                        newVisibleSections[index] = false;
                    }
                });

                // Determine which sections were previously visible but are not anymore
                const sectionsNoLongerVisible = wasVisibleSections.map(
                    (wasVisible, index) => wasVisible && !newVisibleSections[index]
                );

                setVisibleSections(newVisibleSections);

            },
            { threshold: 0.3 }
        );

        // Observe each section element
        const sectionIds = props.sections.map((section, index) => `section${index}`);
        sectionIds.forEach((id) => {
            const section = document.getElementById(id);
            if (section) {
                observer.observe(section);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, [visibleSections, props.sections]);


    // Detect the first visible section on page reload
    const detectWasVisibleSections = () => {
        const firstVisibleIndex = visibleSections.findIndex((isVisible) => isVisible);
        if (firstVisibleIndex !== -1) {
            const updatedWasVisibleSections = Array(props.sections.length).fill(false);
            for (let i = 0; i <= firstVisibleIndex; i++) {
                updatedWasVisibleSections[i] = true;
            }
            setWasVisibleSections(updatedWasVisibleSections);
        }
    };

    // Add a useEffect to watch for changes in visibleSections and call the detection function
    useEffect(() => {
        detectWasVisibleSections();
    }, [visibleSections]);


    const renderFadingPageSections = () => {
        return props.sections.map((section, index) => {
            const sectionId = `section${index}`;
            const sectionVisible = visibleSections[index];
            const sectionWasVisible = wasVisibleSections[index]
            const sectionClasses = `w-full opacity-40 ${styleConstants.transitionSlow} 
            ${(sectionVisible === true
                    ? 'translate-y-0 !opacity-100'
                    : sectionWasVisible ? '-translate-y-10' : 'translate-y-10')}`

            return (
                <div key={sectionId} id={sectionId} className={sectionClasses}>
                    {section}
                </div>
            );
        })

    }

    return (
        <>
            {renderFadingPageSections()}
        </>
    )

}
