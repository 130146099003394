import React, { useEffect, useState } from 'react';
import {
    ZoomableGroup,
    ComposableMap,
    Geographies,
    Geography,
} from "react-simple-maps";
import chroma from 'chroma-js';
import geoData from "../../assets/mapdata/geodata.json"
import { Tooltip } from 'react-tooltip'
import { TBackendMapDataNetwork } from '../../types/backend-data';
import { Transition } from '@headlessui/react';
import { styleConstants } from '../../style-constants';

interface IMapChartProps {
    networkMapData: TBackendMapDataNetwork
}

export const MapChart: React.FC<IMapChartProps> = (props) => {

    const [isMapDragging, setIsMapDragging] = useState<boolean>(false);
    const [tooltipContent, setTooltipContent] = useState<JSX.Element>(<></>);
    const [isTooltipShowing, setIsTooltipShowing] = useState<boolean>(true)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mapKey, setMapKey] = useState(0); // Unique key to force remounting


    const mapColors = {
        min: "rgb(180,180,180)",
        middle_low: styleConstants.middlePurple,
        middle_high: styleConstants.middleDarkPurple,
        max: styleConstants.darkPurple,
        hover: '#fff',
    }
    const mapSize = {
        width: 800,
        height: 300,
    }
    const mapShift = 10
    const maxShare = Math.max(...Object.values(props.networkMapData).map(country => country.share));
    const colorScale = chroma.scale([mapColors.min, mapColors.middle_low, mapColors.middle_low, mapColors.middle_low, mapColors.middle_low, mapColors.middle_high, mapColors.middle_high, mapColors.middle_high, mapColors.middle_high, mapColors.max])
        .mode('rgb')
        .domain([0, Math.log(maxShare) / (Math.log(1.2)*2)]);


    const createTooltipContent = (countryName: string, count: number, share: number) => {
        const tooltipContent = (
            <div>
                <h1 className='font-bold'>{countryName}</h1>
                <span>{count} {count === 1 ? "node" : "nodes"} ({share}%)</span>
            </div>
        )
        setTooltipContent(tooltipContent)
    }

    const clearTooltipContent = () => {
        setTooltipContent(<></>)
    }

    const handleMapMouseUp = () => {
        setIsMapDragging(false);
    };

    const handleMapMouseDown = () => {
        setIsMapDragging(true);
    };

    const handleMapMouseHoverIn = () => {
        setIsTooltipShowing(true);
    };

    const handleMapMouseHoverOut = () => {
        setIsTooltipShowing(false);
    };

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        //force map update on window width change
        setMapKey(prevKey => prevKey + 1);
    }, [windowWidth]);

    // Update the windowWidth state when the window is resized
    useEffect(() => {

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const createGeography = (geo: any) => {
        const geographyId = geo["properties"]["iso_a3"]
        let geographyShare = 0
        let geographyCount = 0
        let geographyColor = mapColors.min
        if (geographyId in props.networkMapData) {
            const countryInfo = props.networkMapData[geographyId]
            geographyShare = countryInfo["share"]
            geographyCount = countryInfo["count"]
            if (geographyShare > 0) {
                geographyColor = colorScale(geographyShare).hex()
            }
        }

        return (
            <Geography
                className="cursor-pointer"
                data-tooltip-id="country-tooltip"
                key={geo["rsmKey"]}
                geography={geo}
                onMouseEnter={() => { createTooltipContent(geo["properties"]["name"], geographyCount, geographyShare) }}
                onMouseLeave={() => { clearTooltipContent() }}
                style={{
                    default: {
                        fill: geographyColor,
                        outline: "none"
                    },
                    hover: {
                        fill: mapColors.hover,
                        outline: "none"
                    }
                }}
            />
        )
    }

    return (
        <div
            onMouseEnter={handleMapMouseHoverIn}
            onMouseLeave={handleMapMouseHoverOut}
            key={mapKey}
            className={`flex w-full h-[180px] sm:h-full ${isMapDragging ? 'cursor-grabbing' : 'cursor-grab'}`}
        >
            <ComposableMap
                className='bg-[#111]'
                projection="geoMercator"
                projectionConfig={{
                    rotate: [-mapShift, 0, 0],
                    scale: 120
                }}
                height={mapSize.height}
                width={mapSize.width}
            >

                <ZoomableGroup
                    zoom={1}
                    center={[mapShift, 40]}
                    translateExtent={[
                        [0, -mapSize.height],
                        [mapSize.width, mapSize.height]
                    ]}
                    onMoveStart={handleMapMouseDown}
                    onMoveEnd={handleMapMouseUp}
                >
                    <Geographies geography={geoData}>
                        {({ geographies }) =>
                            geographies.map((geo) => (
                                createGeography(geo)
                            ))
                        }
                    </Geographies>
                </ZoomableGroup>
            </ComposableMap>
            <Transition
                show={isTooltipShowing}
                enter={`transform ${styleConstants.transitionStandard}`}
                enterFrom="opacity-0 scale-50"
                enterTo="opacity-100 scale-100"
                leave={`${styleConstants.transitionStandard}`}
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-50"
            >
                <Tooltip
                    id='country-tooltip'
                    // className="!transition-all !duration-300"
                    float={true}
                    children={tooltipContent}
                />
            </Transition>
        </div>
    )
};

